import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import Logo from '../images/pragribe-logo.svg';

// TODO: Agregar i18n en links.

const navbarLinks = [
  { path: '/', title: 'Inicio' },
  { path: '/products', title: 'Productos' },
];

const Header = ({ pathname, ...restProps }) => {
  return (
    <div className="flex h-64px justify-between items-center bg-white px-4 md:px-6">
      <img className="h-auto w-128px md:w-164px" alt="Logo" src={Logo} />
      {/* Links for tablet and desktops */}
      <div className="h-auto">
        {navbarLinks.map(({ path, title }) => (
          <Link
            key={path}
            to={path}
            className={`text-secondary-dark py-1 mr-3 md:mr-4 text-base md:text-lg font-medium hover:opacity-80 tracking-wide ${
              pathname === path
                ? 'border-secondary-dark border-solid border-b-2'
                : ''
            }`}
          >
            {title}
          </Link>
        ))}
      </div>
    </div>
  );
};

Header.propTypes = {
  pathname: PropTypes.string.isRequired,
};

Header.defaultProps = {
  pathname: '',
};

export default Header;
